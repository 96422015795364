import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3b15b7c0"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "title"
}
const _hoisted_2 = {
  key: 0,
  class: "is-required"
}
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { key: 2 }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { key: 13 }
const _hoisted_7 = { key: 15 }
const _hoisted_8 = {
  key: 0,
  class: "range-labels"
}
const _hoisted_9 = { key: 1 }
const _hoisted_10 = { key: 17 }
const _hoisted_11 = {
  key: 18,
  class: "question-error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_IonInput = _resolveComponent("IonInput")!
  const _component_IonTextarea = _resolveComponent("IonTextarea")!
  const _component_IonCheckbox = _resolveComponent("IonCheckbox")!
  const _component_IonDatetime = _resolveComponent("IonDatetime")!
  const _component_IonLabel = _resolveComponent("IonLabel")!
  const _component_IonRadio = _resolveComponent("IonRadio")!
  const _component_IonItem = _resolveComponent("IonItem")!
  const _component_IonRadioGroup = _resolveComponent("IonRadioGroup")!
  const _component_IonList = _resolveComponent("IonList")!
  const _component_IonSelectOption = _resolveComponent("IonSelectOption")!
  const _component_IonSelect = _resolveComponent("IonSelect")!
  const _component_IonRange = _resolveComponent("IonRange")!
  const _component_IonCol = _resolveComponent("IonCol")!
  const _component_IonRow = _resolveComponent("IonRow")!
  const _component_IonGrid = _resolveComponent("IonGrid")!
  const _component_IonCardContent = _resolveComponent("IonCardContent")!
  const _component_IonCard = _resolveComponent("IonCard")!

  return (_openBlock(), _createBlock(_component_IonCard, {
    class: _normalizeClass(["question-card", { 'inline-card': _ctx.inline, 'ion-no-margin': _ctx.inline }])
  }, {
    default: _withCtx(() => [
      _createVNode(_component_IonCardContent, {
        class: _normalizeClass({ 'ion-no-padding': _ctx.inline })
      }, {
        default: _withCtx(() => [
          (_ctx.type !== 'note')
            ? (_openBlock(), _createElementBlock("h2", _hoisted_1, [
                _createTextVNode(_toDisplayString(_ctx.title) + " ", 1),
                (_ctx.required)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_2, "*"))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true),
          (_ctx.type === 'note')
            ? (_openBlock(), _createElementBlock("div", {
                key: 1,
                innerHTML: _ctx.title
              }, null, 8, _hoisted_3))
            : _createCommentVNode("", true),
          (_ctx.type === 'bool')
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode(_component_Button, {
                  class: _normalizeClass(["question-button", { active: _ctx.value == 1 }]),
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.answer(1)))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t("yes")), 1)
                  ]),
                  _: 1
                }, 8, ["class"]),
                _createVNode(_component_Button, {
                  class: _normalizeClass(["question-button", { active: _ctx.value == 0 }]),
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.answer(0)))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t("no")), 1)
                  ]),
                  _: 1
                }, 8, ["class"])
              ]))
            : _createCommentVNode("", true),
          (_ctx.type === 'string')
            ? (_openBlock(), _createBlock(_component_IonInput, {
                key: 3,
                onChange: _cache[2] || (_cache[2] = ($event: any) => (_ctx.answer($event.target.value))),
                clearInput: "true",
                color: "primary",
                inputmode: "text",
                type: "text",
                value: _ctx.value,
                placeholder: _ctx.t('tests.typeHere'),
                readonly: _ctx.readonly
              }, null, 8, ["value", "placeholder", "readonly"]))
            : _createCommentVNode("", true),
          (_ctx.type === 'textarea' && !_ctx.readonly)
            ? (_openBlock(), _createBlock(_component_IonTextarea, {
                key: 4,
                onChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.answer($event.target.value))),
                rows: "5",
                value: _ctx.value,
                placeholder: _ctx.t('tests.typeHere'),
                readonly: _ctx.readonly
              }, null, 8, ["value", "placeholder", "readonly"]))
            : _createCommentVNode("", true),
          (_ctx.type === 'textarea' && _ctx.readonly)
            ? (_openBlock(), _createElementBlock("div", {
                key: 5,
                innerHTML: _ctx.nl2br(_ctx.value)
              }, null, 8, _hoisted_5))
            : _createCommentVNode("", true),
          (_ctx.type === 'number')
            ? (_openBlock(), _createBlock(_component_IonInput, {
                key: 6,
                onChange: _cache[4] || (_cache[4] = ($event: any) => (_ctx.answer($event.target.value))),
                clearInput: "true",
                color: "primary",
                inputmode: "number",
                type: "number",
                value: _ctx.value,
                placeholder: _ctx.t('tests.typeHere'),
                readonly: _ctx.readonly
              }, null, 8, ["value", "placeholder", "readonly"]))
            : _createCommentVNode("", true),
          (_ctx.type === 'checkbox')
            ? (_openBlock(), _createBlock(_component_IonCheckbox, {
                key: 7,
                onIonChange: _cache[5] || (_cache[5] = ($event: any) => (_ctx.answer($event.detail.checked))),
                class: "ion-margin-top",
                disabled: _ctx.readonly
              }, null, 8, ["disabled"]))
            : _createCommentVNode("", true),
          (_ctx.type === 'date')
            ? (_openBlock(), _createBlock(_component_IonDatetime, {
                key: 8,
                onIonChange: _cache[6] || (_cache[6] = ($event: any) => (_ctx.answer($event.detail.value))),
                min: _ctx.minDate,
                max: _ctx.maxDate,
                value: _ctx.value,
                placeholder: _ctx.t('tests.selectDate'),
                disabled: _ctx.readonly
              }, null, 8, ["min", "max", "value", "placeholder", "disabled"]))
            : _createCommentVNode("", true),
          (_ctx.type === 'radio')
            ? (_openBlock(), _createBlock(_component_IonList, { key: 9 }, {
                default: _withCtx(() => [
                  _createVNode(_component_IonRadioGroup, {
                    value: _ctx.value,
                    onIonChange: _cache[7] || (_cache[7] = ($event: any) => (_ctx.answer($event.detail.value)))
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
                        return (_openBlock(), _createBlock(_component_IonItem, {
                          key: option.name
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_IonLabel, { "text-wrap": "" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(option.name), 1)
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_IonRadio, {
                              slot: "start",
                              value: option.id,
                              disabled: _ctx.readonly,
                              mode: "md"
                            }, null, 8, ["value", "disabled"])
                          ]),
                          _: 2
                        }, 1024))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["value"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.type === 'select')
            ? (_openBlock(), _createBlock(_component_IonSelect, {
                key: 10,
                placeholder: _ctx.t('tests.selectDropdown'),
                value: _ctx.value,
                onIonChange: _cache[8] || (_cache[8] = ($event: any) => (_ctx.answer($event.detail.value))),
                disabled: _ctx.readonly
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
                    return (_openBlock(), _createBlock(_component_IonSelectOption, {
                      key: option.name,
                      value: option.id
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(option.name), 1)
                      ]),
                      _: 2
                    }, 1032, ["value"]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["placeholder", "value", "disabled"]))
            : _createCommentVNode("", true),
          (_ctx.type === 'borg')
            ? (_openBlock(), _createBlock(_component_IonSelect, {
                key: 11,
                placeholder: _ctx.t('tests.selectDropdown'),
                value: _ctx.value,
                onIonChange: _cache[9] || (_cache[9] = ($event: any) => (_ctx.answer($event.detail.value))),
                disabled: _ctx.readonly
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_IonSelectOption, { value: "" }, {
                    default: _withCtx(() => [
                      _createTextVNode("Hoe zwaar is de belasting?")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_IonSelectOption, { value: "6" }, {
                    default: _withCtx(() => [
                      _createTextVNode("6")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_IonSelectOption, { value: "7" }, {
                    default: _withCtx(() => [
                      _createTextVNode("7 - Zeer zeer licht")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_IonSelectOption, { value: "8" }, {
                    default: _withCtx(() => [
                      _createTextVNode("8")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_IonSelectOption, { value: "9" }, {
                    default: _withCtx(() => [
                      _createTextVNode("9 - Zeer licht")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_IonSelectOption, { value: "10" }, {
                    default: _withCtx(() => [
                      _createTextVNode("10")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_IonSelectOption, { value: "11" }, {
                    default: _withCtx(() => [
                      _createTextVNode("11 - Temelijk licht")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_IonSelectOption, { value: "12" }, {
                    default: _withCtx(() => [
                      _createTextVNode("12")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_IonSelectOption, { value: "13" }, {
                    default: _withCtx(() => [
                      _createTextVNode("13 - Redelijk zwaar")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_IonSelectOption, { value: "14" }, {
                    default: _withCtx(() => [
                      _createTextVNode("14")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_IonSelectOption, { value: "15" }, {
                    default: _withCtx(() => [
                      _createTextVNode("15 - Zwaar")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_IonSelectOption, { value: "16" }, {
                    default: _withCtx(() => [
                      _createTextVNode("16")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_IonSelectOption, { value: "17" }, {
                    default: _withCtx(() => [
                      _createTextVNode("17 - Zeer zwaar")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_IonSelectOption, { value: "18" }, {
                    default: _withCtx(() => [
                      _createTextVNode("18")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_IonSelectOption, { value: "19" }, {
                    default: _withCtx(() => [
                      _createTextVNode("19 Zeer zeer zwaar")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_IonSelectOption, { value: "20" }, {
                    default: _withCtx(() => [
                      _createTextVNode("Maximaal")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["placeholder", "value", "disabled"]))
            : _createCommentVNode("", true),
          (_ctx.type === 'checklist')
            ? (_openBlock(), _createBlock(_component_IonSelect, {
                key: 12,
                placeholder: _ctx.t('tests.selectDropdown'),
                multiple: true,
                value: _ctx.value,
                onIonChange: _cache[10] || (_cache[10] = ($event: any) => (_ctx.answer($event.detail.value))),
                disabled: _ctx.readonly
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
                    return (_openBlock(), _createBlock(_component_IonSelectOption, {
                      key: option.name,
                      value: option.id
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(option.name), 1)
                      ]),
                      _: 2
                    }, 1032, ["value"]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["placeholder", "value", "disabled"]))
            : _createCommentVNode("", true),
          (_ctx.type === 'multiButton')
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
                  return (_openBlock(), _createBlock(_component_Button, {
                    class: _normalizeClass(["question-button", { active: _ctx.value == option.id }]),
                    key: option.name,
                    onClick: ($event: any) => (_ctx.answer(option.id))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(option.name), 1)
                    ]),
                    _: 2
                  }, 1032, ["onClick", "class"]))
                }), 128))
              ]))
            : _createCommentVNode("", true),
          (_ctx.type === 'scale')
            ? (_openBlock(), _createBlock(_component_IonRange, {
                key: 14,
                snaps: true,
                min: _ctx.options && _ctx.options.length > 0 ? _ctx.options[0].value : 0,
                max: 
          _ctx.options && _ctx.options.length > 0 ? _ctx.options[_ctx.options.length - 1].value : 0
        ,
                value: _ctx.value,
                onIonChange: _cache[11] || (_cache[11] = ($event: any) => (_ctx.answer($event.detail.value))),
                disabled: _ctx.readonly
              }, null, 8, ["min", "max", "value", "disabled"]))
            : _createCommentVNode("", true),
          (_ctx.type === 'vas')
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                (_ctx.layoutOptions && _ctx.layoutOptions.length > 0)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                      _createVNode(_component_IonLabel, { "text-wrap": "" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.layoutOptions.filter((o) => o.key == 'layout_scale_prefix')[0].value), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_IonLabel, { "text-wrap": "" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.layoutOptions.filter((o) => o.key == 'layout_scale_postfix')[0].value), 1)
                        ]),
                        _: 1
                      })
                    ]))
                  : _createCommentVNode("", true),
                _createVNode(_component_IonRange, {
                  snaps: false,
                  min: 0,
                  max: 100,
                  value: _ctx.value,
                  onIonChange: _cache[12] || (_cache[12] = ($event: any) => (_ctx.answer($event.detail.value))),
                  disabled: _ctx.readonly
                }, null, 8, ["value", "disabled"])
              ]))
            : _createCommentVNode("", true),
          (_ctx.type === 'table')
            ? (_openBlock(), _createBlock(_component_IonGrid, { key: 16 }, {
                default: _withCtx(() => [
                  _createVNode(_component_IonRow, null, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getTableHeaders(), (header) => {
                        return (_openBlock(), _createBlock(_component_IonCol, { key: header }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(header), 1)
                          ]),
                          _: 2
                        }, 1024))
                      }), 128))
                    ]),
                    _: 1
                  }),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getTableRows(), (row) => {
                    return (_openBlock(), _createBlock(_component_IonRow, { key: row }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getTableCols(), (col) => {
                          return (_openBlock(), _createBlock(_component_IonCol, { key: col }, {
                            default: _withCtx(() => [
                              (_ctx.getTextForColumn(row, col) === false)
                                ? (_openBlock(), _createBlock(_component_IonTextarea, {
                                    key: 0,
                                    onIonChange: ($event: any) => (_ctx.saveTableAnswer(row, col, $event.target.value)),
                                    rows: "3",
                                    value: _ctx.getTableAnswer(row, col),
                                    placeholder: _ctx.t('tests.typeHere'),
                                    readonly: _ctx.readonly
                                  }, null, 8, ["onIonChange", "value", "placeholder", "readonly"]))
                                : (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(_ctx.getTextForColumn(row, col)), 1))
                            ]),
                            _: 2
                          }, 1024))
                        }), 128))
                      ]),
                      _: 2
                    }, 1024))
                  }), 128))
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.type === 'calculation')
            ? (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(_ctx.value), 1))
            : _createCommentVNode("", true),
          (_ctx.error)
            ? (_openBlock(), _createElementBlock("p", _hoisted_11, _toDisplayString(_ctx.error), 1))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["class"])
    ]),
    _: 1
  }, 8, ["class"]))
}