
import { defineComponent } from "vue";
import {
  IonCard,
  IonCardContent,
  IonInput,
  IonDatetime,
  IonTextarea,
  IonCheckbox,
  IonRadioGroup,
  IonRadio,
  IonLabel,
  IonItem,
  IonList,
  IonSelect,
  IonSelectOption,
  IonRange,
  IonGrid,
  IonRow,
  IonCol
} from "@ionic/vue";
import { useI18n } from "vue-i18n";
import Button from "@/components/Button.vue";
import moment from "moment";

export default defineComponent({
  name: "Question",
  components: {
    Button,
    IonCard,
    IonCardContent,
    IonInput,
    IonDatetime,
    IonTextarea,
    IonCheckbox,
    IonRadioGroup,
    IonRadio,
    IonLabel,
    IonItem,
    IonList,
    IonSelect,
    IonSelectOption,
    IonRange,
    IonGrid,
    IonRow,
    IonCol
  },
  props: {
    title: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    required: {
      type: Boolean,
      required: true
    },
    validator: String,
    value: String,
    options: Array as any,
    layoutOptions: {
      type: Array,
      required: true
    } as any,
    readonly: {
      type: Boolean,
      default: false
    },
    error: String,
    inline: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const { t } = useI18n();

    return {
      t
    };
  },
  computed: {
    minDate(): string | undefined {
      return this.validator === "date_in_future"
        ? moment()
            .add(1, "days")
            .format("YYYY-MM-DD")
        : undefined;
    },
    maxDate(): string {
      return this.validator === "date_in_past"
        ? moment()
            .subtract(1, "days")
            .format("YYYY-MM-DD")
        : "2200";
    },
    getRangeValue(): number {
      if (!this.value || this.options.length < 1) {
        return 0;
      }

      return this.options.findIndex(
        (option: any) => option.value == this.value
      );
    }
  },
  methods: {
    answer(answer: any) {
      if (this.readonly) {
        return;
      }

      if (this.type === "date") {
        answer = moment(answer).format("YYYY-MM-DD");
      }

      if (this.type === "checkbox" && !answer) {
        answer = null;
      }

      this.$emit("answer", this.name, answer);
    },

    // Table functions
    getTableRows() {
      if (!Array.isArray(this.layoutOptions) || this.layoutOptions.length < 2) {
        return 0;
      }

      if (this.type !== "table" && this.layoutOptions.length > 1) {
        return 0;
      }

      const option = this.layoutOptions.find(o => o.key === "layout_table_rows");

      if (! option) {
        return 0;
      }

      // Remove one because of the header.
      const amount = Math.trunc(option.value);

      return Array(amount).keys();
    },
    getTableCols() {
      if (!Array.isArray(this.layoutOptions) || this.layoutOptions.length < 1) {
        return 0;
      }

      if (this.type !== "table" && this.layoutOptions.length > 1) {
        return 0;
      }

      return Array(this.getTableHeaders().length).keys();
    },
    getTableHeaders() {
      if (!Array.isArray(this.layoutOptions) || this.layoutOptions.length < 1) {
        return 0;
      }
      const value = this.layoutOptions.filter((o) => o.key == 'layout_table_definition')[0].value;
      const rows = value.split("\r\n");

      return rows[0].split(",");
    },
    getTextForColumn(row: number, col: number) {
      if (!Array.isArray(this.layoutOptions) || this.layoutOptions.length < 1) {
        return 0;
      }

      const value = this.layoutOptions.filter((o) => o.key == 'layout_table_definition')[0].value;
      const rows = value.split("\r\n")[row + 1].split(",");

      return rows[col] === undefined || rows[col].trim() === ""
        ? false
        : rows[col];
    },
    getTableAnswers() {
      try {
        const answers = this.value ? JSON.parse(this.value) : null;

        return answers ?? {};
      } catch (e) {
        return {};
      }
    },
    getTableAnswer(row: number, col: number) {
      const key = `${this.name}_${row}_${col}`;
      const answers = this.getTableAnswers();

      return answers[key] ?? null;
    },
    saveTableAnswer(row: number, col: number, answer: any) {
      const key = `${this.name}_${row}_${col}`;
      const answers = this.getTableAnswers();

      answers[key] = answer;

      this.answer(JSON.stringify(answers));
    },

    nl2br (str: string) {
     const breakTag = '<br />';
     const replaceStr = '$1'+ breakTag;
     return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, replaceStr);
    }
  }
});
