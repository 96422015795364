import { resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loading = _resolveComponent("Loading")!
  const _component_Alert = _resolveComponent("Alert")!
  const _component_QuestionComponent = _resolveComponent("QuestionComponent")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", null, [
    _withDirectives(_createVNode(_component_Loading, null, null, 512), [
      [_vShow, _ctx.loading]
    ]),
    (!_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_ctx.readonly)
            ? (_openBlock(), _createBlock(_component_Alert, {
                key: 0,
                "no-margin": true,
                class: _normalizeClass({ 'ion-margin': !_ctx.inline, 'ion-margin-vertical': _ctx.inline }),
                message: _ctx.t('tests.previewExisting')
              }, null, 8, ["class", "message"]))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredQuestions, (question) => {
            return (_openBlock(), _createBlock(_component_QuestionComponent, {
              onAnswer: _ctx.setAnswer,
              key: question.id,
              name: question.name,
              title: 
          typeof question.title === 'object'
            ? question?.title?.value
            : question.title
        ,
              type: question.type,
              required: question.assistant?.required ?? false,
              validator: question.assistant?.validators ?? [],
              value: _ctx.getAnswer(question.name),
              options: question.options,
              "layout-options": question.layout_options,
              readonly: _ctx.readonly,
              error: _ctx.errors[question.name] ?? '',
              inline: _ctx.inline
            }, null, 8, ["onAnswer", "name", "title", "type", "required", "validator", "value", "options", "layout-options", "readonly", "error", "inline"]))
          }), 128)),
          (!_ctx.readonly)
            ? (_openBlock(), _createBlock(_component_Button, {
                key: 1,
                expand: "full",
                class: "ion-margin",
                onClick: _ctx.submit
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t("tests.sendNew")), 1)
                ]),
                _: 1
              }, 8, ["onClick"]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ]))
}