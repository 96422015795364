
import { defineComponent } from "vue";
import { isEqual } from 'lodash';
import Button from "@/components/Button.vue";
import QuestionComponent from "@/components/tests/Question.vue";
import { useI18n } from "vue-i18n";
import ApiService from "@/services/api.service";
import { toastController } from "@ionic/vue";
import Loading from "@/components/Loading.vue";
import { Question } from "@/interfaces/test";
import Alert from "@/components/Alert.vue";

export default defineComponent({
  name: "Test",
  components: {
    Alert,
    Loading,
    Button,
    QuestionComponent
  },
  props: {
    id: {
      type: Number,
      required: true
    },
    inline: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const { t } = useI18n();

    return {
      t
    };
  },
  data() {
    return {
      data: {} as any,
      name: "",
      questions: [] as Question[],
      answers: [] as any,
      readonly: false,
      errors: {} as any,
      loading: false
    };
  },
  computed: {
    filteredQuestions() {
      const questionsToDisplay = [] as Question[];

      Array.from(this.questions).forEach(question => {
        let addToQuestions = true;

        if (question.dependencies) {
          for (const dependency of question.dependencies) {
            const value = this.answers[`item_${dependency.dependend_item_id}`];

            if (value === undefined) {
              addToQuestions = false;
              continue;
            }

            switch (dependency.dependend_operator) {
              case "=":
                if (value != dependency.dependend_value) {
                  addToQuestions = false;
                }
                break;
              case "!":
                if (value == dependency.dependend_value) {
                  addToQuestions = false;
                }
                break;
            }
          }
        }

        if (addToQuestions) {
          questionsToDisplay.push(question);
        }
      });

      return questionsToDisplay;
    }
  },
  methods: {
    ionViewWillEnter() {
      this.getTest();
    },

    async getTest() {
      this.loading = true;

      if (!this.id) {
        return;
      }

      const { data } = await ApiService.get(`/tasks/tests/${this.id}`);

      this.$emit("title", data.name);
      this.name = data.name;
      this.answers = data.answers;
      this.readonly = data.readonly;
      this.data = data;

      console.log(this.data );

      this.questions = data.items.map((question: Question) => {
        const options = question.assistant ? question.assistant.options?.map(
            (option: any) => {
              return {
                id: option.id,
                name: option.label,
                value: option.value
              };
            }
        ) : [];

        return {
          ...question,
          options
        };
      });
      this.loading = false;
    },
    setAnswer(name: string, answer: any) {
      if (isEqual(this.answers[name], answer)) {
        return;
      }

      this.answers[name] = answer;

      const question = this.questions.find(
          (question: any) => question.name === name
      );
      if (question) {
        this.validateQuestion(question);
      }

      this.autoSave(name, answer);
    },
    getAnswer(name: string) {
      return this.answers[name];
    },
    async submit() {
      if (!(await this.validateQuestionAnswers())) {
        const toast = await toastController.create({
          message: this.t("tests.invalid"),
          duration: 3000
        });

        await toast.present();
        return;
      }

      const final = this.data;

      final.answers = this.answers;

      try {
        await ApiService.put(`/tasks/tests/${final.id}`, final);

        const toast = await toastController.create({
          message: this.t("tests.submitted"),
          duration: 3000
        });

        this.readonly = true;
        await toast.present();
      } catch (e) {
        Object.entries(e.response.data.errors).forEach(([key, errors]: any) => {
          return this.errors[key] = errors[0];
        })
      }
    },
    async autoSave(name: string, answer: any) {
      const final = {
        answers: {
          [name]: answer,
        },
      };

      try {
        await ApiService.put(`/tasks/tests/${this.data.id}/hotsave`, final);
      } catch (e) {
        Object.entries(e.response.data.errors).forEach(([key, errors]: any) => {
          return this.errors[key] = errors[0];
        })
      }
    },
    async validateQuestionAnswers() {
      this.errors = {};
      let isValid = true;

      for (const question of this.filteredQuestions) {
        if (!(await this.validateQuestion(question))) {
          isValid = false;
        }
      }

      return isValid;
    },
    async validateQuestion(question: Question) {
      delete this.errors[question.name];

      const answer = this.answers[question.name];
      const rules = question.assistant;

      if (rules && rules.required) {
        if (answer === undefined || answer === null) {
          this.errors[question.name] = this.t("tests.validation.required");
          return false;
        }
      }

      if (question.type === "number") {
        if (
            rules &&
            rules.validators === "min" &&
            parseInt(rules.validator_config1) > answer
        ) {
          this.errors[question.name] = this.t("tests.validation.min", {
            number: rules.validator_config1
          });
          return false;
        }
      }

      return true;
    }
  },
  created() {
    this.getTest();
  }
});
